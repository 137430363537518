import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Layout, Button, Row, Col, Divider } from "antd";
import EventsHome from "./EventsHome";
import ManageEvents from "./ManageEvents";

const { Content } = Layout;

const Events = () => {
  const navigate = useNavigate();

  return (
    <div style={{ width: "75%", margin: "0 auto", backgroundColor: "#fff" }}>
      <div style={{ position: "sticky", top: 60, zIndex: 1, backgroundColor: "#fff" }}>
        <Row type="flex" justify="space-between" align="middle">
          <Col>
            <h2 style={{ paddingLeft: "15px" }}>Events</h2>
          </Col>
          <Col>
            <Button type="link" onClick={() => navigate("")}>
              Home
            </Button>
            <Button type="link" onClick={() => navigate("manage")}>
              Manage
            </Button>
          </Col>
        </Row>
        <Divider style={{ margin: 0 }} />
      </div>
      <Content style={{ padding: "10px" }}>
        <Routes>
          <Route path="manage" element={<ManageEvents />} />
          <Route index element={<EventsHome />} />
        </Routes>
      </Content>
    </div>
  );
};

export default Events;
