import React, { useState, useRef, useEffect } from "react";
import { Button, InputNumber, Alert, Row, Col, Typography } from "antd";
import Confetti from "react-confetti";
import {
  RedoOutlined,
  PlayCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import useWindowSize from "./useWindowSize"; // Custom hook for window size

const { Title } = Typography;

const NumberGuessingGame = () => {
  const [targetNumber, setTargetNumber] = useState(null);
  const [userGuess, setUserGuess] = useState(null);
  const [feedback, setFeedback] = useState("");
  const [attempts, setAttempts] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const { width, height } = useWindowSize();
  const inputRef = useRef(null);

  useEffect(() => {
    if (targetNumber !== null && inputRef.current) {
      inputRef.current.focus(); // Focus on the input field after number is generated
    }
  }, [targetNumber]);

  // Generate a random number between 1 and 100
  const generateRandomNumber = () => {
    const randomNumber = Math.floor(Math.random() * 100) + 1;
    setTargetNumber(randomNumber);
    setUserGuess(null);
    setFeedback("");
    setAttempts(0);
    setGameOver(false);
  };

  const handleGuess = () => {
    if (gameOver || targetNumber === null) return;

    setAttempts(attempts + 1);
    if (userGuess > targetNumber) {
      setFeedback("Guess a lower number, try again.");
    } else if (userGuess < targetNumber) {
      setFeedback("Guess a higher number, try again.");
    } else {
      setFeedback(`Correct! You took ${attempts + 1} attempts.`);
      setGameOver(true);
    }
  };

  const revealNumber = () => {
    if (targetNumber === null) return;
    setFeedback(`The correct number was ${targetNumber}.`);
    setGameOver(true);
  };

  const endGame = () => {
    setTargetNumber(null); // Reset the target number to hide the game view
    setGameOver(false); // Ensure game state is reset
    // setScore(null); // Clear the score when ending the game
  };

  return (
    <div style={{ padding: "20px" }}>
      <Title level={4} style={{ marginTop: 0 }}>
        Number Guessing
      </Title>
      {gameOver && userGuess === targetNumber && (
        <Confetti width={width} height={height} />
      )}
      <Row justify="center" style={{ marginTop: "20px" }}>
        <Col span={8}>
          {targetNumber !== null && !gameOver ? (
            <Button
              danger
              onClick={endGame}
              style={{ width: "100%" }} // Make the button occupy 100% width
              icon={<CloseCircleOutlined />}
            >
              End Game
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={generateRandomNumber}
              style={{ width: "100%" }} // Make the button occupy 100% width
              icon={<PlayCircleOutlined />}
            >
              Start Game
            </Button>
          )}
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: "20px" }}>
        {targetNumber !== null && (
          <Col span={8} style={{ textAlign: "center", marginBottom: "16px" }}>
            <InputNumber
              ref={inputRef} // Attach the ref here
              min={1}
              max={100}
              value={userGuess}
              onChange={setUserGuess}
              style={{
                width: "100%", // Make the input occupy 100% width
                height: "100px",
                fontSize: "60px",
                textAlign: "center", // Centers the text horizontally
                lineHeight: "100px", // Centers the text vertically
              }}
              disabled={gameOver}
            />
          </Col>
        )}
      </Row>
      <Row justify="center" style={{ marginTop: "20px" }}>
        {targetNumber !== null && (
          <>
            <Col span={8} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                ghost
                onClick={handleGuess}
                disabled={gameOver || userGuess === null}
                style={{ width: "48%", marginRight: "5px" }}
              >
                Submit Guess
              </Button>
            </Col>
            <Col span={8} style={{ textAlign: "left" }}>
              <Button
                default
                onClick={revealNumber}
                style={{ marginLeft: "5px", width: "48%" }}
                disabled={gameOver}
              >
                Reveal Number
              </Button>
            </Col>
          </>
        )}
      </Row>
      <Row justify="center" style={{ marginTop: "20px" }}>
        {feedback && (
          <Col span={8} style={{ textAlign: "center" }}>
            <Alert
              message={feedback}
              type={gameOver && userGuess === targetNumber ? "success" : "info"}
              showIcon
              style={{ width: "100%" }} // Make the alert occupy 100% width
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default NumberGuessingGame;
