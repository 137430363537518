import React from "react";
import { Divider, Row, Typography } from "antd";
import ChallengeCard from "./ChallengeCard";
import "./Challenges.css";

const { Title } = Typography;

const UpcomingChallenges = ({ upcomingChallenges, onEnroll, onWithdraw, showDrawer }) => (
  <>
    <Title level={5} style={{ marginTop: "10px" }}>
      Upcoming Challenges
    </Title>
    <Divider style={{ marginTop: "5px" }}/>
    <div className="challenges-cards-container">
      {upcomingChallenges &&
        upcomingChallenges.map((challenge, index) => (
          <ChallengeCard
            key={index}
            challenge={challenge}
            onEnroll={onEnroll}
            onWithdraw={onWithdraw}
            showDrawer={showDrawer}
          />
        ))}
    </div>
  </>
);

export default UpcomingChallenges;
