import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Layout, Button, Row, Col, Divider } from "antd";
import AppreciatePeer from "./appreciate/AppreciatePeer";
import PeerReviewHome from "./home/PeerReviewHome";
import TeamFeedback from "./team/TeamFeedback";

const { Content } = Layout;

const PeerReview = () => {
  const navigate = useNavigate();

  return (
    <div style={{ width: "75%", margin: "0 auto", backgroundColor: "#fff" }}>
      <div style={{ position: "sticky", top: 60, zIndex: 1, backgroundColor: "#fff" }}>
        <Row type="flex" justify="space-between" align="middle">
          <Col>
            <h2 style={{ paddingLeft: "15px" }}>Peer Review</h2>
          </Col>
          <Col>
            <Button type="link" onClick={() => navigate("")}>
              Home
            </Button>
            <Button type="link" onClick={() => navigate("appreciate")}>
              Appreciate
            </Button>
            {/* <Button type="link" onClick={() => navigate("team-feedback")}>
              Team
            </Button> */}
          </Col>
        </Row>
        <Divider style={{ margin: 0 }} />
      </div>
      <Content style={{ padding: "10px" }}>
        <Routes>
          {/* <Route path="team-feedback" element={<TeamFeedback />} /> */}
          <Route path="appreciate" element={<AppreciatePeer />} />
          <Route index element={<PeerReviewHome />} />
        </Routes>
      </Content>
    </div>
  );
};

export default PeerReview;
