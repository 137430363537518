import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Radio,
  Form,
  Input,
  Progress,
  Card,
  Spin,
  message,
  Empty,
  Drawer,
  List,
  Layout,
  Typography,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { AuthContext } from "../login/AuthContext";
import useApiClient from "../../apiClient";

const { Content } = Layout;
const { Title } = Typography;

const PollResults = ({ refresh }) => {
  const apiClient = useApiClient();
  const { user } = useContext(AuthContext);
  const [polls, setPolls] = useState([]);
  const [loading, setLoading] = useState(false); // Spinner state
  const [form] = Form.useForm();
  const [drawerVisible, setDrawerVisible] = useState(false); // Drawer visibility state

  useEffect(() => {
    fetchPolls();
  }, [refresh]);

  const fetchPolls = async () => {
    setLoading(true); // Show spinner
    try {
      const response = await apiClient.get("/api/polls");
      setPolls(response.data);
    } catch (error) {
      message.error("Failed to load polls.");
    }
    setLoading(false); // Hide spinner
  };

  const submitVote = async (pollId, selectedOptionIndex) => {
    try {
      await apiClient.post("/api/polls/vote", {
        pollId,
        selectedOptionIndex,
        userId: user.id,
      });
      fetchPolls();
      message.success("Vote submitted.");
    } catch (error) {
      message.error("Failed to submit vote.");
    }
  };

  // Function to check if the user has already voted
  const userHasVoted = (poll) => {
    return Object.values(poll.results).some(
      (option) => option?.voters?.includes(user.id) // Ensure voters array exists
    );
  };

  return (
    <div>
      {/* Display spinner while loading */}
      {loading ? (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Spin tip="Loading polls..." size="large" />
        </div>
      ) : (
        <div>
          {/* Display Empty message if there are no polls */}
          {polls.length === 0 ? (
            <Empty description="No Polls Available" />
          ) : (
            <List
              dataSource={polls}
              renderItem={(poll) => {
                const totalVotes = Object.values(poll.results).reduce(
                  (acc, curr) => acc + (curr?.count || 0),
                  0
                );

                // Check if the user has already voted
                const voted = userHasVoted(poll);

                return (
                  <Card
                    title={
                      <div>
                        {/* <div>{poll.question}</div> */}
                        <Title
                          level={4}
                          style={{ marginBottom: 0, marginTop: 0 }}
                        >
                          {poll.question}
                        </Title>
                        <div style={{ color: "gray", fontSize: "small" }}>
                          {totalVotes} Votes
                        </div>
                      </div>
                    }
                    style={{ marginBottom: 20 }}
                  >
                    <Radio.Group
                      onChange={(e) => submitVote(poll.id, e.target.value)}
                      disabled={voted} // Disable radio group if user has voted
                      style={{ width: "100%" }}
                    >
                      {poll.options.map((option, index) => (
                        <div key={index} style={{ marginBottom: 10 }}>
                          <Radio
                            value={index}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            {option}
                          </Radio>
                          <Progress
                            percent={
                              totalVotes > 0
                                ? ((poll.results[`option${index + 1}`]?.count ||
                                    0) /
                                    totalVotes) *
                                  100
                                : 0
                            }
                            size={["100%", 10]}
                            format={(percent) => `${percent.toFixed(1)}%`}
                          />
                        </div>
                      ))}
                    </Radio.Group>
                  </Card>
                );
              }}
            />
          )}
        </div>
      )}

      {/* Drawer for creating new poll */}
      {/* <Drawer
        title="Create a New Poll"
        width={400}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form form={form} onFinish={createPoll} layout="vertical">
          <Form.Item
            name="question"
            label="Question"
            rules={[{ required: true, message: "Please enter the question!" }]}
          >
            <Input placeholder="Enter poll question" />
          </Form.Item>
          <Form.List
            name="options"
            rules={[
              {
                validator: async (_, options) => {
                  if (!options || options.length < 2) {
                    return Promise.reject(
                      new Error("Please enter at least two options!")
                    );
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    key={index}
                    {...field}
                    rules={[
                      { required: true, message: "Please enter an option!" },
                    ]}
                  >
                    <Input placeholder={`Option ${index + 1}`} />
                  </Form.Item>
                ))}
                {fields.length < 4 && (
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()}>
                      Add Option
                    </Button>
                  </Form.Item>
                )}
              </>
            )}
          </Form.List>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Create Poll
            </Button>
          </Form.Item>
        </Form>
      </Drawer> */}
    </div>
  );
};

export default PollResults;
