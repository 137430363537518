import React from 'react';
import TipsList from './TipsList';
import TipsData from './TipsData.json';

const Tips = () => {
    
  return (
    <div>
      <h1>Tips</h1>
      <TipsList tips={TipsData} />
    </div>
  );
};

export default Tips;
