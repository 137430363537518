import React, { useState, useEffect } from "react";
import { Button, Input, Row, Col, notification, Space } from "antd";
import {
  ReloadOutlined,
  EyeOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { getSudoku } from "sudoku-gen";

const generateSudoku = () => {
  const { puzzle, solution } = getSudoku("medium"); // Generates a new Sudoku puzzle and its solution

  // Convert the puzzle and solution strings into 2D arrays
  const formattedPuzzle = formatGrid(puzzle);
  const formattedSolution = formatGrid(solution);

  return { puzzle: formattedPuzzle, solution: formattedSolution };
};

const formatGrid = (str) => {
  const size = 9;
  const grid = [];
  for (let i = 0; i < str.length; i += size) {
    grid.push(
      str
        .slice(i, i + size)
        .split("")
        .map((char) => (char === "-" ? null : parseInt(char, 10)))
    );
  }
  return grid;
};

const SudokuBoard = () => {
  const [{ puzzle, solution }, setBoard] = useState({
    puzzle: [],
    solution: [],
  });
  const [userInputs, setUserInputs] = useState([]); // Track user inputs separately
  const [showSolution, setShowSolution] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false); // Manage submit button state

  useEffect(() => {
    const { puzzle, solution } = generateSudoku();
    setBoard({ puzzle, solution });
    setUserInputs(puzzle.map((row) => row.slice())); // Initialize user inputs with the puzzle
    setIsSubmitDisabled(false); // Reset the submit button state
  }, []);

  const newGame = () => {
    const { puzzle, solution } = generateSudoku();
    setBoard({ puzzle, solution });
    setUserInputs(puzzle.map((row) => row.slice())); // Reset user inputs with the new puzzle
    setShowSolution(false);
    setIsSubmitDisabled(false); // Enable submit button for the new game
  };

  const revealSolution = () => {
    setShowSolution(true);
    const updatedInputs = userInputs.map((row, rowIndex) =>
      row.map((cell, colIndex) =>
        cell === null ? solution[rowIndex][colIndex] : cell
      )
    );
    setUserInputs(updatedInputs); // Update userInputs to show the solution
    setIsSubmitDisabled(true); // Disable submit button after revealing solution
  };

  const handleInputChange = (row, col, value) => {
    if (!/^\d*$/.test(value)) return;

    const newInputs = [...userInputs];
    newInputs[row][col] = value === "" ? null : parseInt(value, 10);
    setUserInputs(newInputs);
  };

  const submitSolution = () => {
    const isCorrect = userInputs.every((row, rowIndex) =>
      row.every((cell, colIndex) => cell === solution[rowIndex][colIndex])
    );

    if (isCorrect) {
      notification.success({
        message: "Congratulations!",
        description: "You have solved the Sudoku correctly!",
      });
    } else {
      notification.error({
        message: "Oops!",
        description: "The solution is incorrect. Please try again.",
      });
    }
  };

  const getInputStyle = (row, col) => {
    let style = {
      textAlign: "center",
      padding: "10px",
      fontSize: "18px",
      border: "1px solid #000",
      borderRadius: "0", // Remove rounded corners
      width: "40px",
      height: "40px",
      lineHeight: "40px",
      backgroundColor: puzzle[row][col] !== null ? "#808080" : "#fff", // Original puzzle cells dark, user inputs white
      color: puzzle[row][col] !== null ? "#fff" : "#000",
    };

    // Add thicker borders to separate 3x3 grids
    if (row % 3 === 0 && row !== 0) style.borderTop = "5px solid #000";
    if (col % 3 === 0 && col !== 0) style.borderLeft = "5px solid #000";

    return style;
  };

  return (
    <div>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8} lg={6}>
          <h2 style={{ margin: 0 }}>Sudoku Game</h2>
        </Col>
        <Col xs={24} sm={12} md={16} lg={18} style={{ textAlign: "right" }}>
          <Button
            icon={<ReloadOutlined />}
            onClick={newGame}
            style={{
              marginRight: 10,
              // backgroundColor: "#4CAF50",
              // borderColor: "#4CAF50",
              color: "#4CAF50",
            }}
          >
            New Game
          </Button>
          <Button
            icon={<EyeOutlined />}
            onClick={revealSolution}
            style={{
              marginRight: 10,
              borderColor: "#FF9800",
              color: "#FF9800",
            }}
          >
            Reveal Answer
          </Button>
          <Button
            type="default"
            icon={<CheckCircleOutlined />}
            onClick={submitSolution}
            style={{
              backgroundColor: isSubmitDisabled ? "#2196F3" : "#2196F3",
              color: isSubmitDisabled ? "#fff" : "#fff",
              borderColor: isSubmitDisabled ? "#2196F3" : "#2196F3",
              opacity: isSubmitDisabled ? 0.65 : 1, // Apply some opacity to indicate it's disabled
              cursor: isSubmitDisabled ? "not-allowed" : "pointer", // Change cursor when disabled
            }}
            disabled={isSubmitDisabled}
          >
            Submit Solution
          </Button>
        </Col>
      </Row>
      <div style={{ marginTop: 40 }}>
        {userInputs.map((row, rowIndex) => (
          <Row key={rowIndex} gutter={[0, 0]} justify="center">
            {row.map((cell, colIndex) => (
              <Col key={colIndex}>
                <Input
                  value={
                    showSolution && puzzle[rowIndex][colIndex] === null
                      ? solution[rowIndex][colIndex]
                      : userInputs[rowIndex][colIndex] || ""
                  }
                  onChange={(e) =>
                    handleInputChange(rowIndex, colIndex, e.target.value)
                  }
                  disabled={puzzle[rowIndex][colIndex] !== null}
                  maxLength={1}
                  style={getInputStyle(rowIndex, colIndex)}
                />
              </Col>
            ))}
          </Row>
        ))}
      </div>
    </div>
  );
};

export default SudokuBoard;
