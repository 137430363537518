import React, { useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Layout, Button, Row, Col, Divider } from "antd";
import PollResults from "./PollResults";
import CreatePoll from "./CreatePoll";

const { Content } = Layout;

const Poll = () => {
  const navigate = useNavigate();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [refreshPolls, setRefreshPolls] = useState(false);

  // Function to handle opening the drawer
  const showDrawer = () => {
    setDrawerVisible(true);
  };

  // Function to handle closing the drawer
  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const handlePollRefresh = () => {
    setRefreshPolls((prev) => !prev); // Toggle refresh state
  };

  return (
    <div style={{ width: "75%", margin: "0 auto", backgroundColor: "#fff" }}>
      <div
        style={{
          position: "sticky",
          top: 60,
          zIndex: 1,
          backgroundColor: "#fff",
        }}
      >
        <Row type="flex" justify="space-between" align="middle">
          <Col>
            <h2 style={{ paddingLeft: "15px" }}>Poll</h2>
          </Col>
          <Col>
            <Button type="link" onClick={() => navigate("")}>
              Home
            </Button>
            <Button type="link" onClick={showDrawer}>
              Create
            </Button>
          </Col>
        </Row>
        <Divider style={{ margin: 0 }} />
      </div>
      <Content style={{ padding: "10px" }}>
        <Routes>
          {/* <Route path="manage" element={<ManageEvents />} /> */}
          <Route index element={<PollResults refresh={refreshPolls} />} />
        </Routes>
        {/* Drawer for creating a new poll */}
        <CreatePoll visible={drawerVisible} onClose={closeDrawer} refreshPolls={handlePollRefresh} />
      </Content>
    </div>
  );
};

export default Poll;
