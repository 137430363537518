import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const getCurrentDate = () => {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(now.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

// Get current local date and time
export const getUserLocalDateTime = () => {
  return dayjs().format("YYYY-MM-DD HH:mm:ss"); // Format as YYYY-MM-DD HH:mm:ss
};

// Format given date
export const formatDate = (date) => {
  return dayjs(date).format("MMM D, YYYY h:mm A"); // Format: Sep 5, 2024 10:30 AM
};

// Get user timezone
export const getUserTimezone = () => {
  return dayjs.tz.guess(); // America/Chicago
};

/**
 * Given the time in GMT, return local time in the format: YYYY-MM-DD HH:mm:ss
 * @param {*} gmtTime 
 * @returns 
 */
export const getLocalTimeFromUTC = (gmtTime) => {
  return dayjs.utc(gmtTime).tz(getUserTimezone()).format('YYYY-MM-DD HH:mm:ss');
}

/**
 * Given the time in GMT, return local time in the format: MMM D, YYYY
 * @param {*} gmtTime 
 * @returns 
 */
export const getLocalTimeFromUTC2 = (gmtTime) => {
  return dayjs.utc(gmtTime).tz(getUserTimezone()).format('MMM D, YYYY');
}

/**
 * Given the time in GMT, return local time in the format: MMM D, YYYY hh:mm
 * @param {*} gmtTime 
 * @returns 
 */
export const getLocalTimeFromUTC3 = (gmtTime) => {
  return dayjs.utc(gmtTime).tz(getUserTimezone()).format('MMM D, YYYY hh:mm A');
}
