import React, { useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Drawer,
  Empty,
  Button,
  Typography,
  Form,
  Input,
  InputNumber,
  Divider,
  message,
  Upload,
  Modal,
  Spin,
  Popconfirm,
} from "antd";
import {
  PlusCircleOutlined,
  UploadOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import useApiClient from "../../apiClient";
import { AuthContext } from "../login/AuthContext";
import ManageListingCard from "./ManageListingCard";
import ListingDrawer from "./ListingDrawer";
import CommentsDrawer from "./CommentsDrawer";

const { TextArea } = Input;
const { Title } = Typography;

const ManageListings = () => {
  const apiClient = useApiClient();
  const { user } = useContext(AuthContext);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingListing, setEditingListing] = useState(null);
  const [listingsData, setListingsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [commentsDrawerVisible, setCommentsDrawerVisible] = useState(false); // New state for comments drawer
  const [selectedListingComments, setSelectedListingComments] = useState([]);

  // Method to fetch listings from the backend
  const fetchListings = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get(
        `/api/marketplace/list/user/${user.id}`
      );
      if (response.status === 200) {
        setListingsData(response.data);
      } else {
        message.error("Failed to fetch listings.");
      }
    } catch (error) {
      console.error("Error fetching listings:", error);
      message.error("An error occurred while fetching listings.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch listings when the component mounts
  useEffect(() => {
    fetchListings();
  }, []);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setEditingListing(null);
  };

  const showCommentsDrawer = () => {
    setCommentsDrawerVisible(true);
  };

  const closeCommentsDrawer = () => {
    setCommentsDrawerVisible(false);
    setSelectedListingComments([]);
  };

  const handleMarkSold = async (listingId) => {
    setProcessing(true);
    try {
      const response = await apiClient.post("/api/marketplace/listing/update", {
        id: listingId,
        status: "SOLD",
      });

      if (response.status === 200) {
        message.success("Listing marked as sold", 2);
        fetchListings(); // Update the UI
      } else {
        message.error("Failed to mark listing as sold", 2);
      }
    } catch (error) {
      console.error("Error marking listing as sold:", error);
      message.error("An error occurred while marking the listing as sold", 2);
    } finally {
      setProcessing(false);
    }
  };

  const handleRemoveListing = async (listingId) => {
    setProcessing(true);
    try {
      const response = await apiClient.post("/api/marketplace/listing/update", {
        id: listingId,
        status: "DELETE",
      });

      if (response.status === 200) {
        message.success("Listing removed", 2);
        fetchListings(); // Update the UI
      } else {
        message.error("Failed to remove the listing", 2);
      }
    } catch (error) {
      console.error("Error removing listing:", error);
      message.error("An error occurred while removing the listing", 2);
    } finally {
      setProcessing(false);
    }
  };

  const handleEditListing = (listing) => {
    setEditingListing(listing);
    showDrawer(); // Open the drawer
  };

  const handleShowingComments = (listing) => {
    // Set the selected listing's comments and open the comments drawer
    setSelectedListingComments(listing); // Ensure comments exist
    console.log("listing: ", selectedListingComments);
    showCommentsDrawer(); // Open the comments drawer
  };

  return (
    <Spin
      spinning={loading}
      tip="Loading listings..."
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div>
        {listingsData.length === 0 && !loading ? (
          <div style={{ textAlign: "center" }}>
            <Title level={5}>
              You don't have any active marketplace listings.
            </Title>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={showDrawer}
            >
              Create Listing
            </Button>
          </div>
        ) : (
          <>
            <Row
              justify="space-between"
              align="middle"
              style={{ marginBottom: 16 }}
            >
              <Col>
                <Title level={5} style={{ margin: 0 }}>
                  You have {listingsData.length} listings
                </Title>
              </Col>
              <Col>
                <Button
                  type="default"
                  icon={<PlusCircleOutlined />}
                  onClick={showDrawer}
                >
                  Create Listing
                </Button>
              </Col>
            </Row>
            <div className="marketplace-cards-container">
              {listingsData.map((listing, index) => (
                <ManageListingCard
                  key={index}
                  listing={listing}
                  onMarkSold={() => handleMarkSold(listing.id)}
                  onRemove={() => handleRemoveListing(listing.id)}
                  onEdit={() => handleEditListing(listing)}
                  onComments={() => handleShowingComments(listing)}
                />
              ))}
            </div>
          </>
        )}

        <ListingDrawer
          visible={drawerVisible}
          onClose={closeDrawer}
          listing={editingListing}
          fetchListings={fetchListings} // Refresh listings after saving
        />

        <CommentsDrawer
          visible={commentsDrawerVisible}
          onClose={closeCommentsDrawer}
          comments={selectedListingComments.comments}
          listingId={selectedListingComments.id}
          title={selectedListingComments.title}
          fetchListings={fetchListings} // Refresh listings after saving comment
        />
      </div>
    </Spin>
  );
};

export default ManageListings;
