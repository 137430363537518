import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Input,
  DatePicker,
  TimePicker,
  Select,
  InputNumber,
  Button,
  notification,
  Alert,
  Row,
  Col,
  Drawer,
  Spin,
  Typography,
} from "antd";
import useApiClient from "../../apiClient";
import { AuthContext } from "../login/AuthContext";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import _ from "lodash";

const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

dayjs.extend(utc);
dayjs.extend(timezone);

const EventForm = ({
  title,
  closeDrawer,
  drawerVisible,
  fetchEvents,
  event,
}) => {
  const [loading, setLoading] = useState(false);
  const apiClient = useApiClient();
  const { user } = useContext(AuthContext);
  const userId = user.id;
  const [form] = Form.useForm();
  const [isVirtual, setIsVirtual] = useState(false);
  const [isHybrid, setIsHybrid] = useState(false);
  const [requiresFee, setRequiresFee] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const userTimezone = dayjs.tz.guess(); // Get user's local timezone

  // Initialize form fields when an event is passed in (editing mode)
  useEffect(() => {
    if (event) {
      let eventCopy = _.cloneDeep(event);
      eventCopy.date = dayjs(eventCopy.date).tz(userTimezone);
      eventCopy.startTime = dayjs(eventCopy.startTime, "HH:mm:ss").tz(
        userTimezone
      );
      eventCopy.endTime = dayjs(eventCopy.endTime, "HH:mm:ss").tz(userTimezone);

      setIsVirtual(eventCopy.type === "Virtual");
      setIsHybrid(eventCopy.type === "Hybrid");
      setRequiresFee(eventCopy.price !== "Free");

      form.setFieldsValue(eventCopy); // Set form fields for editing
    } else {
      form.resetFields(); // Reset form for new event creation
      setIsVirtual(false);
      setIsHybrid(false);
      setRequiresFee(false);
    }
  }, [event, form, userTimezone]);

  const openNotification = (type, message, description) => {
    notification[type]({
      message,
      description,
      placement: "topRight",
    });
  };

  const onDrawerClose = () => {
    form.resetFields();
    closeDrawer();
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);

      // Format dates and times in user's local timezone
      const formattedDate = values.date.tz(userTimezone).format("YYYY-MM-DD");
      const formattedStartTime = values.startTime
        .tz(userTimezone)
        .format("HH:mm:ss");
      const formattedEndTime = values.endTime
        .tz(userTimezone)
        .format("HH:mm:ss");
      const createDateTime = dayjs()
        .tz(userTimezone)
        .format("YYYY-MM-DD HH:mm:ss");

      const newEvent = {
        id: event ? event.id : null,
        name: values.name || (event ? event.name : ""),
        date: formattedDate,
        startTime: formattedStartTime,
        endTime: formattedEndTime,
        location: isVirtual ? "Online" : values.location,
        description: values.description,
        type: isVirtual ? "Virtual" : values.type,
        attendeeLimit: values.attendeeLimit || 0,
        price: values.price,
        fee: requiresFee ? values.fee : 0,
        joinLink: isVirtual || isHybrid ? values.joinLink : "",
        participants: event ? event.participants : [],
        host: userId,
        createDateTime, // Creation date time in user's local timezone
      };

      const response = await apiClient.post("/api/events/save", newEvent);

      if (response.status === 201) {
        openNotification(
          "success",
          `Event ${event ? `Updated` : `Created`}`,
          `Your event has been successfully ${event ? `updated` : `created`}.`
        );
        form.resetFields();
        setIsVirtual(false);
        setIsHybrid(false);
        setRequiresFee(false);
        setErrorMessage(null);
        fetchEvents();
        closeDrawer();
      } else {
        throw new Error("Failed to save event");
      }
    } catch (error) {
      console.error("Error creating/updating event:", error);
      setErrorMessage("Failed to save event. Please try again.");
    }
    setLoading(false);
  };

  const handleTypeChange = (value) => {
    setIsVirtual(value === "Virtual");
    setIsHybrid(value === "Hybrid");
  };

  const handlePriceChange = (value) => {
    setRequiresFee(value === "Paid" || value === "Split");
  };

  // Custom validation rule for event date
  const validateFutureDate = (_, value) => {
    if (value && value.isBefore(dayjs(), "day")) {
      return Promise.reject(new Error("Event date must be in the future."));
    }
    return Promise.resolve();
  };

  // Custom validation rule for time range
  const validateTimeRange = (_, value) => {
    const startTime = form.getFieldValue("startTime");
    if (value && startTime && value.isBefore(startTime)) {
      return Promise.reject(
        new Error("End time must be greater than start time.")
      );
    }
    return Promise.resolve();
  };

  return (
    <>
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span>{event ? "Edit Event" : "Create Event"}</span>
            <Button type="text" onClick={() => closeDrawer(false)}>
              Close
            </Button>
          </div>
        }
        placement="right"
        open={drawerVisible}
        width={400}
        closable={false}
        className={"custom-form"}
      >
        <Form
          form={form}
          layout="vertical"
          className="custom-form"
          onFinish={onFinish}
          initialValues={{
            type: "In-Person",
            price: "Free",
            fee: 0,
            attendeeLimit: null,
          }}
          style={{ width: "100%", margin: "0 auto" }}
        >
          {event ? (
            // <Title level={4} style={{ marginTop: 0 }}>
            //   {event.name}
            // </Title>
            <Form.Item
              name="name"
              label={<span><b>Event Name</b></span>}
            >
              <Input placeholder="Enter event name" value={event.name} disabled/>
            </Form.Item>
          ) : (
            <Form.Item
              name="name"
              label={<span><b>Event Name</b></span>}
              rules={[
                { required: true, message: "Please enter the event name" },
              ]}
            >
              <Input placeholder="Enter event name" />
            </Form.Item>
          )}

          <Form.Item
            name="date"
            label={<span><b>Event Date</b></span>}
            rules={[
              { required: true, message: "Please enter the event date" },
              { validator: validateFutureDate },
            ]}
          >
            <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
          </Form.Item>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="startTime"
                label={<span><b>Start Time</b></span>}
                rules={[
                  { required: true, message: "Please enter the start time" },
                ]}
              >
                <TimePicker format="h:mm A" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="endTime"
                label={<span><b>End Time</b></span>}
                rules={[
                  { required: true, message: "Please enter the end time" },
                  { validator: validateTimeRange },
                ]}
              >
                <TimePicker format="h:mm A" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name="type"
            label={<span><b>Event Type</b></span>}
            rules={[
              { required: true, message: "Please select the event type" },
            ]}
          >
            <Select onChange={handleTypeChange}>
              <Option value="In-Person">In-Person</Option>
              <Option value="Virtual">Virtual</Option>
              <Option value="Hybrid">Hybrid</Option>
            </Select>
          </Form.Item>

          {!isVirtual && (
            <Form.Item
              name="location"
              label={<span><b>Event Location</b></span>}
              rules={[
                { required: true, message: "Please enter the event location" },
              ]}
            >
              <Input placeholder="Enter event location" />
            </Form.Item>
          )}

          {(isVirtual || isHybrid) && (
            <Form.Item
              name="joinLink"
              label={<span><b>Join Link</b></span>}
              rules={[
                { required: true, message: "Please enter the join link" },
              ]}
            >
              <Input placeholder="Enter online join link" />
            </Form.Item>
          )}

          <Form.Item
            name="description"
            label={<span><b>Description</b></span>}
            rules={[
              { required: true, message: "Please enter the event description" },
            ]}
          >
            <TextArea rows={4} placeholder="Enter event description" />
          </Form.Item>

          <Form.Item
            name="attendeeLimit"
            label={<span><b>Attendee Limit</b></span>}
          >
            <InputNumber
              min={1}
              placeholder="Enter attendee limit (optional)"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item
            name="price"
            label={<span><b>Event Price</b></span>}
            rules={[
              { required: true, message: "Please select the event price type" },
            ]}
          >
            <Select onChange={handlePriceChange}>
              <Option value="Free">Free</Option>
              <Option value="Paid">Paid</Option>
              <Option value="Split">Split</Option>
            </Select>
          </Form.Item>

          {requiresFee && (
            <Form.Item
              name="fee"
              label={<span><b>Event Fee</b></span>}
              rules={[
                { required: true, message: "Please enter the event fee" },
              ]}
            >
              <InputNumber
                min={0}
                formatter={(value) => `$ ${value}`}
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          )}

          {errorMessage && (
            <Alert
              message={errorMessage}
              type="error"
              showIcon
              style={{ marginBottom: 16 }}
            />
          )}

          <Form.Item style={{ textAlign: "center" }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              loading={loading} // Show loading spinner when submitting
            >
              {event ? "Edit Event" : "Create Event"}
            </Button>
          </Form.Item>
        </Form>

        {/* Custom CSS to remove asterisk for required fields */}
        <style jsx="true">{`
          .custom-form .ant-form-item-required::before {
            display: none !important;
          }
        `}</style>
      </Drawer>
    </>
  );
};

export default EventForm;
