import React, { useState } from "react";
import { Card, Tag, message, Button } from "antd";
import {
  EnvironmentOutlined,
  LaptopOutlined,
  TeamOutlined,
  DollarOutlined,
  LinkOutlined,
  UserOutlined,
} from "@ant-design/icons";
import useApiClient from "../../apiClient";
import "./Events.css";

const EventCard = ({ event = {}, user }) => {
  const apiClient = useApiClient();

  const {
    id: eventId, // Added eventId for API requests
    name = "Unnamed Event",
    date = "",
    time = "",
    location = "",
    description = "",
    type = "Virtual",
    attendeeLimit = "No Limit",
    price = "Free",
    fee = 0,
    joinLink = "",
    isJoined: initialIsJoined = false, // Initial joined state from props
    totalJoined: initialTotalJoined = 0,
    host = "",
  } = event;

  event.isJoined = event.participants.some((participant) => participant.userId === user.id);

  const [isJoined, setIsJoined] = useState(event.isJoined);
  const [totalJoined, setTotalJoined] = useState(initialTotalJoined); // Total joined count

  const isEventFull =
    attendeeLimit &&
    attendeeLimit !== "No Limit" &&
    totalJoined >= attendeeLimit;

  // Determine the tags based on the event type and price
  const eventTypeTag =
    type === "Virtual" ? (
      <Tag color="blue">Virtual</Tag>
    ) : type === "In-Person" ? (
      <Tag color="green">In-Person</Tag>
    ) : (
      <Tag color="purple">Hybrid</Tag>
    );

  const priceTag =
    price === "Free" ? (
      <Tag color="#108ee9">Free</Tag>
    ) : price === "Paid" ? (
      <Tag color="red">Paid</Tag>
    ) : (
      <Tag color="orange">Split</Tag>
    );

  const eventFullTag = isEventFull ? <Tag color="#f50">Event Full</Tag> : null;

  // Function to handle joining the event
  const handleJoinEvent = async () => {
    console.log("Attempting to join the event");
    try {
      const response = await apiClient.post("/api/events/join", {
        userId: user.id,
        eventId,
        joinedAt: new Date().toISOString(), // Assuming current time in ISO format
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Get user's timezone
      });
      if (response.status === 200) {
        message.success("You joined the event");
        setIsJoined(true); // Update join state
        setTotalJoined(totalJoined + 1); // Increment total joined count
      }
    } catch (error) {
      message.error("Error joining event");
    }
  };

  // Function to handle leaving the event
  const handleLeaveEvent = async () => {
    try {
      const response = await apiClient.post("/api/events/leave", {
        userId: user.id,
        eventId,
      });
      if (response.status === 200) {
        message.success("You left the event");
        setIsJoined(false); // Update join state
        setTotalJoined(totalJoined - 1); // Decrement total joined count
      }
    } catch (error) {
      message.error("Error leaving event");
    }
  };

  return (
    <Card
      title={
        <div>
          <div>{name}</div>
          <div style={{ color: "gray", fontSize: "small" }}>
            {date} | {time}
          </div>
          {type === "In-Person" && (
            <div
              style={{ color: "#1E3A8A", fontSize: "small", fontWeight: "300" }}
            >
              <EnvironmentOutlined style={{ color: "green" }} /> {location}
            </div>
          )}
          {type === "Virtual" && (
            <div
              style={{ color: "#1E3A8A", fontSize: "small", fontWeight: "300" }}
            >
              <LaptopOutlined style={{ color: "blue" }} /> Online
            </div>
          )}
          {type === "Hybrid" && (
            <div
              style={{ color: "#1E3A8A", fontSize: "small", fontWeight: "300" }}
            >
              <EnvironmentOutlined style={{ color: "green" }} /> {location} |{" "}
              <LaptopOutlined style={{ color: "blue" }} /> Online
            </div>
          )}
        </div>
      }
      className="event-card"
      // hoverable
    >
      <div className="event-card-content">
        <div className="tags">
          {eventTypeTag}
          {priceTag}
          {eventFullTag}
        </div>
        <p className="description">{description}</p>
        {type !== "In-Person" && joinLink && (
          <p>
            <LinkOutlined />{" "}
            <a href={joinLink} target="_blank" rel="noopener noreferrer">
              Join Online
            </a>
          </p>
        )}
        {(price === "Paid" || price === "Split") && (
          <p>
            <DollarOutlined /> ${fee}
          </p>
        )}
        <div className="card-event-people-metrics">
          <p>
            <TeamOutlined />{" "}
            {attendeeLimit ? `${attendeeLimit} Limit` : "No Limit"}
          </p>
          <p>
            <UserOutlined /> {totalJoined} Joined
          </p>
        </div>
      </div>
      {isEventFull ? (
        <Button
          type="primary"
          ghost
          danger
          disabled
          className="event-join-button"
        >
          Event Full
        </Button>
      ) : isJoined ? (
        <Button
          type="default"
          danger
          ghost
          className="event-join-button"
          onClick={handleLeaveEvent} // Leave Event
        >
          Leave Event
        </Button>
      ) : (
        <Button
          type="primary"
          className="event-join-button"
          onClick={handleJoinEvent} // Join Event
        >
          Join Event
        </Button>
      )}
    </Card>
  );
};

export default EventCard;
