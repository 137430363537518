import React from 'react';
import Subnav from '../subnav/subnav';

const Home = () => {
  return (
    <div>
      <h1>Welcome to My Website</h1>
      <p>This is the home page content.</p>
      {/* <Subnav /> */}
    </div>
  );
};

export default Home;
