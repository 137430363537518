import React from 'react';
import './Tips.css';

const TipsList = ({ tips }) => {
    
  return (
    <div className="item-list-container">
      {tips.map((item) => (
        <div
          key={item.id}
          className="item"
        >
          <h2 className="item-heading">{item.heading}</h2>
          <p className="item-subheading">{item.subheading}</p>
        </div>
      ))}
    </div>
  );
};

export default TipsList;
