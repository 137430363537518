import React from "react";
import { Card, Col, Divider, Row } from "antd";
import "./Games.css";

const { Meta } = Card;

const GamesHome = ({ games, onGameClick }) => {
  return (
    <div style={{ padding: '0px', marginTop: '20px' }}>
    <Row gutter={[16, 16]}>
      {games.map((game, index) => (
        <Col
          key={index}
          xs={24}
          sm={12}
          md={12}
          lg={8}
          xl={8}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Card
            hoverable
            style={{ width: '100%', maxWidth: '300px' }}
            cover={<img alt={game.title} src={game.image} style={{ width: '100%', height: '250px', objectFit: 'cover' }}/>}
            onClick={() => onGameClick(game.path)}
          >
            {/* <Divider/> */}
            <Meta title={game.title} description={game.description} />
          </Card>
        </Col>
      ))}
    </Row>
  </div>
  );
};

export default GamesHome;
