import React, { useState, useEffect } from "react";
import ListingCard from "./ListingCard"; // Make sure you have ListingCard component imported
import { message, Spin, Pagination, Row, Col, Typography, Empty } from "antd";
import useApiClient from "../../apiClient";
import "./Marketplace.css";

const { Title } = Typography;

const MarketplaceHome = () => {
  const apiClient = useApiClient();
  const [listings, setListings] = useState([]); // State to hold listings data
  const [loading, setLoading] = useState(true); // State to handle loading
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [totalItems, setTotalItems] = useState(0);

  // Fetch listings from the backend
  const fetchListings = async (page = 1, pageSize = 10) => {
    setLoading(true);
    try {
      const response = await apiClient.get(
        `/api/marketplace/list?page=${page}&pageSize=${pageSize}`
      );

      if (response.status === 200) {
        setListings(response.data.listings);
        setTotalItems(response.data.totalItems); // Set total items for pagination
      } else {
        message.error("Failed to fetch listings.");
      }
    } catch (error) {
      console.error("Error fetching listings:", error);
      message.error("An error occurred while fetching listings.");
    } finally {
      setLoading(false); // Set loading to false after fetch operation
    }
  };

  // Fetch listings when the component mounts and when currentPage/pageSize changes
  useEffect(() => {
    fetchListings(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const updateCommentCount = (listingId) => {
    setListings((prevListings) =>
      prevListings.map((listing) =>
        listing.id === listingId
          ? { ...listing, commentsCount: listing.commentsCount + 1 }
          : listing
      )
    );
  };

  return (
    <Spin
      spinning={loading}
      size="large"
      tip="Loading listings..."
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
        <Col>
          <Title level={5} style={{ margin: 0 }}>
            {totalItems} Listings
          </Title>
        </Col>
        <Col>
          <Pagination
            simple={{
              readOnly: true,
            }}
            defaultCurrent={1}
            total={totalItems}
            onChange={handlePageChange}
          />
        </Col>
      </Row>
      <div className="marketplace-cards-container">
        {listings && listings.length > 0 ? (
          listings.map((listing, index) => (
            <ListingCard
              listing={listing}
              key={index}
              onComment={updateCommentCount}
            />
          ))
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    </Spin>
  );
};

export default MarketplaceHome;
