import React, { useState, useEffect } from "react";
import { Layout, Typography, Row, Col, Button, Card } from "antd";
import "./Game2048.css";
import Paragraph from "antd/es/typography/Paragraph";

const { Title, Text } = Typography;
const { Content } = Layout;
const { Meta } = Card;

const GRID_SIZE = 4;

const Game2048 = () => {
  const [grid, setGrid] = useState([]);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);

  useEffect(() => {
    initializeGame();
  }, []);

  const initializeGame = () => {
    const newGrid = Array(GRID_SIZE)
      .fill(null)
      .map(() => Array(GRID_SIZE).fill(0));
    addRandomTile(newGrid);
    addRandomTile(newGrid);
    setGrid(newGrid);
    setScore(0);
    setGameOver(false);
  };

  const addRandomTile = (grid) => {
    const emptyTiles = [];
    grid.forEach((row, rowIndex) => {
      row.forEach((value, colIndex) => {
        if (value === 0) {
          emptyTiles.push({ x: rowIndex, y: colIndex });
        }
      });
    });

    if (emptyTiles.length > 0) {
      const { x, y } =
        emptyTiles[Math.floor(Math.random() * emptyTiles.length)];
      grid[x][y] = Math.random() < 0.9 ? 2 : 4;
    }
  };

  const handleMove = (direction) => {
    if (gameOver) return;

    let moved = false;
    let newGrid = [...grid].map((row) => [...row]);

    if (direction === "LEFT") {
      for (let row = 0; row < GRID_SIZE; row++) {
        const [newRow, rowMoved] = moveRowLeft(newGrid[row]);
        newGrid[row] = newRow;
        moved = moved || rowMoved;
      }
    } else if (direction === "RIGHT") {
      for (let row = 0; row < GRID_SIZE; row++) {
        const [newRow, rowMoved] = moveRowLeft([...newGrid[row]].reverse());
        newGrid[row] = newRow.reverse();
        moved = moved || rowMoved;
      }
    } else if (direction === "UP") {
      for (let col = 0; col < GRID_SIZE; col++) {
        const column = newGrid.map((row) => row[col]);
        const [newCol, colMoved] = moveRowLeft(column);
        newCol.forEach((value, index) => {
          newGrid[index][col] = value;
        });
        moved = moved || colMoved;
      }
    } else if (direction === "DOWN") {
      for (let col = 0; col < GRID_SIZE; col++) {
        const [newCol, colMoved] = moveRowLeft(
          newGrid.map((row) => row[col]).reverse()
        );
        newCol.reverse().forEach((value, index) => {
          newGrid[index][col] = value;
        });
        moved = moved || colMoved;
      }
    }

    if (moved) {
      addRandomTile(newGrid);
      setGrid(newGrid);
      if (checkGameOver(newGrid)) {
        setGameOver(true);
      }
    }
  };

  const moveRowLeft = (row) => {
    const newRow = row.filter((value) => value !== 0);
    let moved = newRow.length !== row.length;
    for (let i = 0; i < newRow.length - 1; i++) {
      if (newRow[i] === newRow[i + 1]) {
        newRow[i] *= 2;
        setScore((prevScore) => prevScore + newRow[i]);
        newRow[i + 1] = 0;
        moved = true;
      }
    }
    const compactedRow = newRow.filter((value) => value !== 0);
    return [
      [...compactedRow, ...Array(GRID_SIZE - compactedRow.length).fill(0)],
      moved,
    ];
  };

  const checkGameOver = (grid) => {
    for (let row = 0; row < GRID_SIZE; row++) {
      for (let col = 0; col < GRID_SIZE; col++) {
        if (grid[row][col] === 0) return false;
        if (col < GRID_SIZE - 1 && grid[row][col] === grid[row][col + 1])
          return false;
        if (row < GRID_SIZE - 1 && grid[row][col] === grid[row + 1][col])
          return false;
      }
    }
    return true;
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case "ArrowLeft":
          handleMove("LEFT");
          break;
        case "ArrowRight":
          handleMove("RIGHT");
          break;
        case "ArrowUp":
          handleMove("UP");
          break;
        case "ArrowDown":
          handleMove("DOWN");
          break;
        default:
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [grid]);

  return (
    <Content>
      <Title level={4} style={{ marginTop: 0 }}>
        2048
      </Title>
      {gameOver && (
        <Title level={4} style={{ textAlign: "center", color: "red" }}>
          Game Over
        </Title>
      )}
      <Row justify="center" style={{ marginTop: "20px" }}>
        <Col>
          <div className="grid">
            {grid.map((row, rowIndex) => (
              <div key={rowIndex} className="row">
                {row.map((value, colIndex) => (
                  <div key={colIndex} className={`cell cell-${value}`}>
                    {value !== 0 ? value : ""}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: "20px" }}>
        <Card style={{ width: "100%" }}>
          <Row gutter={16}>
            <Col span={12}>
              <Title level={4} style={{margin: 0}}>Your Score</Title>
              <Paragraph>{score}</Paragraph>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button danger onClick={initializeGame}>
                Restart Game
              </Button>
            </Col>
          </Row>
        </Card>
      </Row>
    </Content>
  );
};

export default Game2048;
