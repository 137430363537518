import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Empty, Button, Typography, message, Spin } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import ManageEventCard from "./ManageEventCard";
import { AuthContext } from "../login/AuthContext";
import useApiClient from "../../apiClient";
import EventForm from "./EventForm";

const { Title } = Typography;

const ManageEvents = () => {
  const apiClient = useApiClient();
  const { user } = useContext(AuthContext);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingEvent, setEditingEvent] = useState(null);
  const [eventsData, setEventsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalEvents, setTotalEvents] = useState(0);

  // Method to fetch events from the backend
  const fetchEvents = async () => {
    setLoading(true);
    const userId = user.id;
    try {
      const response = await apiClient.get(
        `/api/events?page=${currentPage}&limit=10&host=${userId}`
      );
      if (response.status === 200) {
        const data = response.data;
        setEventsData(data.events);
      } else {
        message.error("Failed to fetch events.");
      }
    } catch (error) {
      console.error("Error fetching events:", error);
      message.error("An error occurred while fetching events.");
    }
    setLoading(false);
  };

  // Fetch listings when the component mounts
  useEffect(() => {
    fetchEvents();
  }, [currentPage]);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setEditingEvent(null);
  };

  const handleRemoveEvent = async (eventId) => {
    try {
      const response = await apiClient.delete(`/api/events/${eventId}`);

      if (response.status === 200) {
        message.success("Event removed", 2);
        fetchEvents(); // Update the UI
      } else {
        message.error("Failed to remove the event", 2);
      }
    } catch (error) {
      console.error("Error removing event:", error);
      message.error("An error occurred while removing the event", 2);
    }
  };

  const handleEditEvent = (event) => {
    setEditingEvent(event);
    showDrawer(); // Open the drawer
  };

  return (
    <Spin
      spinning={loading}
      size="large"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div>
        {!loading && eventsData.length === 0 ? (
          <div style={{ textAlign: "center" }}>
            <Title level={5}>You don't have any active events.</Title>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={showDrawer}
            >
              Create Event
            </Button>
          </div>
        ) : (
          <>
            <Row
              justify="space-between"
              align="middle"
              style={{ marginBottom: 16 }}
            >
              <Col>
                <Title level={5} style={{ margin: 0 }}>
                  You have {eventsData.length} events
                </Title>
              </Col>
              <Col>
                <Button
                  type="default"
                  icon={<PlusCircleOutlined />}
                  onClick={showDrawer}
                >
                  Create Event
                </Button>
              </Col>
            </Row>
            <div className="marketplace-cards-container">
              {eventsData.map((event, index) => (
                <ManageEventCard
                  key={index}
                  event={event}
                  onEdit={() => handleEditEvent(event)}
                  onRemove={() => handleRemoveEvent(event.id)}
                />
              ))}
            </div>
          </>
        )}
        <EventForm
          closeDrawer={closeDrawer}
          drawerVisible={drawerVisible}
          fetchEvents={fetchEvents}
          event={editingEvent}
        />
      </div>
    </Spin>
  );
};

export default ManageEvents;
