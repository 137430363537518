import React, { useState, useContext } from "react";
import {
  Card,
  Tag,
  Image,
  Space,
  Typography,
  Row,
  Statistic,
  Drawer,
  List,
  Divider,
  Avatar,
  Input,
  Button,
  Form,
  Carousel,
} from "antd";
import {
  CommentOutlined,
  UserOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { message } from "antd";
import { AuthContext } from "../login/AuthContext";
import useApiClient from "../../apiClient";
import "./Marketplace.css";
import {
  getLocalTimeFromUTC2,
  getLocalTimeFromUTC3,
} from "../../utils/DateUtils";

const { Title, Text } = Typography;
const { TextArea } = Input;

const ListingCard = ({ listing, onComment }) => {
  const apiClient = useApiClient();
  const { user } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [newComment, setNewComment] = useState("");

  const {
    id,
    pics,
    title,
    description,
    price,
    category,
    isAvailable,
    createdAt,
    commentsCount,
    creatorName,
    comments,
  } = listing;

  const statusTag = isAvailable ? (
    <Tag color="green">Available</Tag>
  ) : (
    <Tag color="#f50">Sold</Tag>
  );

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const getInitials = (name) => {
    return name
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };

  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const renderImages = (pics) => {
    return pics && pics.length > 0 ? (
      pics.map((pic, index) => (
        <Image
          key={index}
          src={pic}
          alt={`Listing image ${index + 1}`}
          style={{
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            width: "100%",
            height: "250px",
            objectFit: "cover",
          }}
        />
      ))
    ) : (
      <Image
        key={0}
        src={`https://tubby.blob.core.windows.net/marketplace-pics/no-image-uploaded.webp`}
        alt={`No-image-uploaded`}
        style={{
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          width: "100%",
          height: "250px",
          objectFit: "cover",
        }}
      />
    );
  };

  const handlePostComment = async () => {
    const userId = user.id;
    const userName = user.first_name + " " + user.last_name;

    try {
      const response = await apiClient.post("/api/marketplace/item/comment", {
        listingId: id,
        userId,
        userName,
        comment: newComment,
      });

      if (response.status === 201) {
        const data = response.data;
        message.success("Comment posted successfully!", 2);
        // Clear the comment input after posting
        setNewComment("");

        if (comments) {
          // Add the new comment as the first item in the existing comments
          comments.unshift(data);
          onComment(id);
        } else {
          comments = [];
          // If it's first comment
          comments.push(data);
          onComment(id);
        }
      } else {
        message.error("Failed to post comment.", 2);
      }
    } catch (error) {
      console.error("Error posting comment:", error);
      message.error("An error occurred while posting the comment.", 2);
    }
  };

  return (
    <>
      <Card
        hoverable
        style={{ width: 300, borderRadius: 10, margin: "8px" }}
        cover={
          <Carousel arrows autoplay>
            {renderImages(pics)}
          </Carousel>
        }
        className={"listing-card"}
      >
        <Space
          direction="vertical"
          size="small"
          style={{ width: "100%" }}
          onClick={showDrawer}
        >
          <Title
            level={4}
            style={{
              marginTop: 10,
              marginBottom: 0,
              paddingBottom: 0,
              color: "#2C2C2C",
            }}
          >
            {title}
          </Title>

          <Row
            justify="flex-start"
            align="middle"
            className="marketplace-listing-detail"
          >
            <Tag color="#108ee9">{category}</Tag>
            {statusTag}
          </Row>

          <Row
            justify="space-between"
            align="middle"
            className="marketplace-listing-detail"
          >
            {category === "Free" ? (
              <Statistic
                value={0}
                precision={0}
                prefix="$"
                valueStyle={{ color: "#9e8600" }}
              />
            ) : (
              <Statistic
                value={price}
                precision={2}
                prefix="$"
                valueStyle={{ color: "green" }}
              />
            )}
          </Row>

          <Text className="marketplace-description">{description}</Text>

          <Space>
            <CalendarOutlined style={{ fontSize: "16px", color: "#93c" }} />
            <Text type="secondary">
              Listed on {getLocalTimeFromUTC2(createdAt)}
            </Text>
          </Space>
          <Space>
            <CommentOutlined style={{ fontSize: "16px", color: "#08c" }} />
            <Text type="secondary">{commentsCount} Comments</Text>
          </Space>
          <Space>
            <UserOutlined style={{ fontSize: "16px", color: "#31k" }} />
            <Text type="secondary">{creatorName}</Text>
          </Space>
        </Space>
      </Card>

      <Drawer
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span>{title}</span>
            <Button type="text" onClick={onClose}>
              Close
            </Button>
          </div>
        }
        placement="right"
        onClose={onClose}
        closable={false}
        open={visible}
        width={400}
        footer={
          <Form>
            <Form.Item>
              <TextArea
                rows={3}
                value={newComment}
                onChange={handleCommentChange}
                placeholder="Write your comment here..."
              />
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <Button
                type="primary"
                onClick={handlePostComment}
                disabled={!newComment.trim()}
                style={{ width: "100%" }}
              >
                Post Comment
              </Button>
            </Form.Item>
          </Form>
        }
      >
        <Carousel arrows={false} infinite={false} dotPosition="bottom">
          {renderImages(pics)}
        </Carousel>

        <Title level={4}>{title}</Title>

        <Text type="secondary" style={{ fontSize: "12px" }}>
          Listed by <b>{creatorName}</b> on{" "}
          <b>{getLocalTimeFromUTC2(createdAt)}</b>
        </Text>

        <Row
          justify="flex-start"
          align="middle"
          className="marketplace-listing-detail"
          style={{ marginTop: "10px" }}
        >
          {statusTag}
        </Row>

        <Row
          justify="space-between"
          align="middle"
          className="marketplace-listing-detail"
        >
          {category === "Free" ? (
            <Statistic
              value={0}
              precision={0}
              prefix="$"
              valueStyle={{ color: "#9e8600" }}
            />
          ) : (
            <Statistic
              value={price}
              precision={2}
              prefix="$"
              valueStyle={{ color: "green" }}
            />
          )}
        </Row>

        <Space size="small" style={{ marginTop: 10 }}>
          <Text style={{ marginTop: 16 }}>{description}</Text>
        </Space>

        <Divider />
        <Title level={5} style={{ marginTop: 0 }}>
          Comments:
        </Title>
        {comments ? (
          <List
            itemLayout="horizontal"
            dataSource={comments}
            renderItem={(comment) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    // <UserOutlined style={{ fontSize: "16px", color: "#888" }} />
                    <Avatar
                      style={{
                        backgroundColor: "#888",
                        verticalAlign: "middle",
                      }}
                    >
                      {getInitials(comment.userName)}
                    </Avatar>
                  }
                  title={
                    <Text
                      style={{
                        marginBottom: 0,
                        display: "block",
                        fontWeight: 500,
                      }}
                    >
                      {comment.userName}
                    </Text>
                  }
                  description={
                    <>
                      <Text
                        type="secondary"
                        style={{
                          fontSize: "12px",
                          marginBottom: 10,
                          marginTop: 0,
                          display: "block",
                        }}
                      >
                        {getLocalTimeFromUTC3(comment.created)}
                      </Text>
                      <Text style={{ marginTop: 0 }}>{comment.comment}</Text>
                    </>
                  }
                />
              </List.Item>
            )}
          />
        ) : null}
      </Drawer>
    </>
  );
};

export default ListingCard;
